import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CategoryStatement from '../components/Statement/categoryStatement'
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import { MainSectionContainer } from '../components/Section/section.css'
import FeatureStatement from "../components/Statement/featureStatement"
import TagSection from '../components/Section/tagSection/tagSection'
import CardList from '../components/article/articleList/articleList'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'



const Video = ({ data }) => {
  console.log("data ", data)
  const tags = data.tagsGroup.group
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const posts = data.allMarkdownRemark.edges
  console.log("url", url)
  const postsPerPage = 9
  let featured;
  const postsWithoutFeatured = posts.filter(({ node }) => {
    if (node.frontmatter.featured) {
      featured = node
    }
    return !node.frontmatter.featured
  })
  const numPages = Math.ceil(postsWithoutFeatured.length / postsPerPage)
  const subheader = "Interviews, \n Quick Lessons \n and other watchable content from the Ntheos team."

  const SEO = {
    pageInfo: "Ntheos Mag | Video Content"
  }

  return (
    <Layout seo={SEO}>
      <CategoryStatement category="video" subheader={subheader} />

      <Section section='tags' header bg='white' heading='' size='auto'>
        <TagSection type="video" tags={tags} />
      </Section>

      {
        featured && featured !== undefined &&
        <Section section='featured' size='medium'>
          <Link to={`${featured.fields.slug}`}>
            <FeatureStatement imageType="gif" cover={featured.frontmatter.coverGif}
              title={featured.frontmatter.title} type="category" color='1' />
          </Link>
          <MainSectionContainer type="category" />
        </Section>
      }

      <Section bg='white' section="latest" size='auto'>
        <CardList posts={postsWithoutFeatured} />
      </Section>

    </Layout>
  )
};

Video.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Video;

export const query = graphql`
 query VideoPageQuery {
  tagsGroup: allMarkdownRemark(

    filter: {frontmatter: {type: {eq: "vid"}}}
  
  ) {
    group(field: frontmatter___tags) {
      totalCount
      fieldValue
    }
  }
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "vid"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "ddd MMMM Do, YYYY")
          tags
          opener
          featured
          coverGif  
        }
      }
    }
  }
}
`;